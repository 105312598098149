/* eslint-disable */
// sessions v0.0.1 48681273e3b0249c5feb593b9af1b59dc6a14869
// --
// Code generated by webrpc-gen@v0.22.1 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=sessions.ridl -target=typescript -client -out=./clients/sessions.gen.ts

export const WebrpcHeader = 'Webrpc'

export const WebrpcHeaderValue = 'webrpc@v0.22.1;gen-typescript@v0.16.2;sessions@v0.0.1'

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.0.1'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = '48681273e3b0249c5feb593b9af1b59dc6a14869'

type WebrpcGenVersions = {
  webrpcGenVersion: string
  codeGenName: string
  codeGenVersion: string
  schemaName: string
  schemaVersion: string
}

export function VersionFromHeader(headers: Headers): WebrpcGenVersions {
  const headerValue = headers.get(WebrpcHeader)
  if (!headerValue) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  return parseWebrpcGenVersions(headerValue)
}

function parseWebrpcGenVersions(header: string): WebrpcGenVersions {
  const versions = header.split(';')
  if (versions.length < 3) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  const [_, webrpcGenVersion] = versions[0].split('@')
  const [codeGenName, codeGenVersion] = versions[1].split('@')
  const [schemaName, schemaVersion] = versions[2].split('@')

  return {
    webrpcGenVersion,
    codeGenName,
    codeGenVersion,
    schemaName,
    schemaVersion
  }
}

//
// Types
//

export enum SignatureType {
  EIP712 = 'EIP712',
  EthSign = 'EthSign',
  EIP1271 = 'EIP1271'
}

export interface RuntimeStatus {
  healthy: boolean
  started: string
  uptime: number
  version: string
  branch: string
  commit: string
  arweave: ArweaveStatus
}

export interface ArweaveStatus {
  nodeURL: string
  namespace: string
  sender: string
  signer: string
  flushInterval: string
  bundleDelay: string
  bundleLimit: number
  confirmations: number
  lockTTL: string
  healthy: boolean
  lastFlush?: string
  lastFlushSeconds?: number
}

export interface Info {
  wallets: { [key: string]: number }
  configs: { [key: string]: number }
  configTrees: number
  migrations: { [key: string]: number }
  signatures: number
  digests: number
  recorder: RecorderInfo
  arweave: ArweaveInfo
}

export interface RecorderInfo {
  requests: number
  buffer: number
  lastFlush?: string
  lastFlushSeconds?: number
  endpoints: { [key: string]: number }
}

export interface ArweaveInfo {
  nodeURL: string
  namespace: string
  sender: ArweaveSenderInfo
  signer: string
  flushInterval: string
  bundleDelay: string
  bundleLimit: number
  confirmations: number
  lockTTL: string
  healthy: boolean
  lastFlush?: string
  lastFlushSeconds?: number
  bundles: number
  pending: ArweavePendingInfo
}

export interface ArweaveSenderInfo {
  address: string
  balance: string
}

export interface ArweavePendingInfo {
  wallets: number
  configs: number
  migrations: number
  signatures: number
  bundles: Array<ArweaveBundleInfo>
}

export interface ArweaveBundleInfo {
  transaction: string
  block: number
  items: number
  sentAt: string
  confirmations: number
}

export interface Context {
  version: number
  factory: string
  mainModule: string
  mainModuleUpgradable: string
  guestModule: string
  walletCreationCode: string
}

export interface Signature {
  digest: string
  toImageHash?: string
  chainID: string
  type: SignatureType
  signature: string
  validOnChain?: string
  validOnBlock?: string
  validOnBlockHash?: string
}

export interface SignerSignature {
  signer?: string
  signature: string
  referenceChainID?: string
}

export interface ConfigUpdate {
  toImageHash: string
  signature: string
}

export interface Transaction {
  to: string
  value?: string
  data?: string
  gasLimit?: string
  delegateCall?: boolean
  revertOnError?: boolean
}

export interface TransactionBundle {
  executor: string
  transactions: Array<Transaction>
  nonce: string
  signature: string
}

export interface Sessions {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  config(args: ConfigArgs, headers?: object, signal?: AbortSignal): Promise<ConfigReturn>
  wallets(args: WalletsArgs, headers?: object, signal?: AbortSignal): Promise<WalletsReturn>
  deployHash(args: DeployHashArgs, headers?: object, signal?: AbortSignal): Promise<DeployHashReturn>
  configUpdates(args: ConfigUpdatesArgs, headers?: object, signal?: AbortSignal): Promise<ConfigUpdatesReturn>
  migrations(args: MigrationsArgs, headers?: object, signal?: AbortSignal): Promise<MigrationsReturn>
  saveConfig(args: SaveConfigArgs, headers?: object, signal?: AbortSignal): Promise<SaveConfigReturn>
  saveWallet(args: SaveWalletArgs, headers?: object, signal?: AbortSignal): Promise<SaveWalletReturn>
  saveSignature(args: SaveSignatureArgs, headers?: object, signal?: AbortSignal): Promise<SaveSignatureReturn>
  saveSignerSignatures(
    args: SaveSignerSignaturesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SaveSignerSignaturesReturn>
  saveSignerSignatures2(
    args: SaveSignerSignatures2Args,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SaveSignerSignatures2Return>
  saveMigration(args: SaveMigrationArgs, headers?: object, signal?: AbortSignal): Promise<SaveMigrationReturn>
}

export interface PingArgs {}

export interface PingReturn {}
export interface ConfigArgs {
  imageHash: string
}

export interface ConfigReturn {
  version: number
  config: any
}
export interface WalletsArgs {
  signer: string
  cursor?: number
  limit?: number
}

export interface WalletsReturn {
  wallets: { [key: string]: Signature }
  cursor: number
}
export interface DeployHashArgs {
  wallet: string
}

export interface DeployHashReturn {
  deployHash: string
  context: Context
}
export interface ConfigUpdatesArgs {
  wallet: string
  fromImageHash: string
  allUpdates?: boolean
}

export interface ConfigUpdatesReturn {
  updates: Array<ConfigUpdate>
}
export interface MigrationsArgs {
  wallet: string
  fromVersion: number
  fromImageHash: string
  chainID?: string
}

export interface MigrationsReturn {
  migrations: { [key: string]: { [key: number]: { [key: string]: TransactionBundle } } }
}
export interface SaveConfigArgs {
  version: number
  config: any
}

export interface SaveConfigReturn {}
export interface SaveWalletArgs {
  version: number
  deployConfig: any
}

export interface SaveWalletReturn {}
export interface SaveSignatureArgs {
  wallet: string
  digest: string
  chainID: string
  signature: string
  toConfig?: any
  referenceChainID?: string
}

export interface SaveSignatureReturn {}
export interface SaveSignerSignaturesArgs {
  wallet: string
  digest: string
  chainID: string
  signatures: Array<string>
  toConfig?: any
}

export interface SaveSignerSignaturesReturn {}
export interface SaveSignerSignatures2Args {
  wallet: string
  digest: string
  chainID: string
  signatures: Array<SignerSignature>
  toConfig?: any
}

export interface SaveSignerSignatures2Return {}
export interface SaveMigrationArgs {
  wallet: string
  fromVersion: number
  toVersion: number
  toConfig: any
  executor: string
  transactions: Array<Transaction>
  nonce: string
  signature: string
  chainID?: string
}

export interface SaveMigrationReturn {}

//
// Client
//
export class Sessions implements Sessions {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Sessions/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  config = (args: ConfigArgs, headers?: object, signal?: AbortSignal): Promise<ConfigReturn> => {
    return this.fetch(this.url('Config'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <number>_data.version,
            config: <any>_data.config
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  wallets = (args: WalletsArgs, headers?: object, signal?: AbortSignal): Promise<WalletsReturn> => {
    return this.fetch(this.url('Wallets'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            wallets: <{ [key: string]: Signature }>_data.wallets,
            cursor: <number>_data.cursor
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deployHash = (args: DeployHashArgs, headers?: object, signal?: AbortSignal): Promise<DeployHashReturn> => {
    return this.fetch(this.url('DeployHash'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            deployHash: <string>_data.deployHash,
            context: <Context>_data.context
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  configUpdates = (args: ConfigUpdatesArgs, headers?: object, signal?: AbortSignal): Promise<ConfigUpdatesReturn> => {
    return this.fetch(this.url('ConfigUpdates'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            updates: <Array<ConfigUpdate>>_data.updates
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  migrations = (args: MigrationsArgs, headers?: object, signal?: AbortSignal): Promise<MigrationsReturn> => {
    return this.fetch(this.url('Migrations'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            migrations: <{ [key: string]: { [key: number]: { [key: string]: TransactionBundle } } }>_data.migrations
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveConfig = (args: SaveConfigArgs, headers?: object, signal?: AbortSignal): Promise<SaveConfigReturn> => {
    return this.fetch(this.url('SaveConfig'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveWallet = (args: SaveWalletArgs, headers?: object, signal?: AbortSignal): Promise<SaveWalletReturn> => {
    return this.fetch(this.url('SaveWallet'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveSignature = (args: SaveSignatureArgs, headers?: object, signal?: AbortSignal): Promise<SaveSignatureReturn> => {
    return this.fetch(this.url('SaveSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveSignerSignatures = (
    args: SaveSignerSignaturesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SaveSignerSignaturesReturn> => {
    return this.fetch(this.url('SaveSignerSignatures'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveSignerSignatures2 = (
    args: SaveSignerSignatures2Args,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SaveSignerSignatures2Return> => {
    return this.fetch(this.url('SaveSignerSignatures2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  saveMigration = (args: SaveMigrationArgs, headers?: object, signal?: AbortSignal): Promise<SaveMigrationReturn> => {
    return this.fetch(this.url('SaveMigration'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  const reqHeaders: { [key: string]: string } = { ...headers, 'Content-Type': 'application/json' }
  reqHeaders[WebrpcHeader] = WebrpcHeaderValue

  return {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = `endpoint error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = `request failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = `bad route`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = `bad method`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = `bad request`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = `bad response`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = `server panic`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = `internal error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = `client disconnected`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = `stream lost`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = `stream finished`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 1,
    message: string = `invalid argument`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(name: string = 'NotFound', code: number = 2, message: string = `not found`, status: number = 0, cause?: string) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  InvalidArgument = 'InvalidArgument',
  NotFound = 'NotFound'
}

export enum WebrpcErrorCodes {
  WebrpcEndpoint = 0,
  WebrpcRequestFailed = -1,
  WebrpcBadRoute = -2,
  WebrpcBadMethod = -3,
  WebrpcBadRequest = -4,
  WebrpcBadResponse = -5,
  WebrpcServerPanic = -6,
  WebrpcInternalError = -7,
  WebrpcClientDisconnected = -8,
  WebrpcStreamLost = -9,
  WebrpcStreamFinished = -10,
  InvalidArgument = 1,
  NotFound = 2
}

export const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1]: InvalidArgumentError,
  [2]: NotFoundError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
